@import './scss/fonts/_font';
@import './scss/_reset';
@import './scss/variables';
@import './scss/functions';
@import './scss/_mixins';

/* Buttons Start */
.btn {
	display: inline-block;
	padding: 0 25px;
	height: 54px;
	line-height: 54px;
	background-color: color('red');
	color: color('white');
	transition: color 0.2s;
	&:hover {
		color: color('blue');
	}
}
/* Buttons End*/
/* Container Start */
.container {
	width: $container-width;
	margin: 0 auto;
	position: position('relative');
	@media (max-width: $breakpoint-xxlg) {
		width: 100%;
		padding: 0 20px;
	}
}
/* Container End */
/* NavBar Start */
.navbar-top {
	@include flex(row, wrap, flex-start, flex-end, 0 0);
	min-height: 167px;
}
.navbar {
	min-height: 103px;
	background: $blue-gradient;
	position: relative;
	border-bottom: 14px solid color('golden-yellow');
	z-index: 1050;
	@include flex();
	&.fixed-top {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		animation: slideDown 0.4s linear forwards;
		@include keyframes(slideDown) {
			from {
				transform: translate3d(0, -100%, 0);
				visibility: visible;
			}

			to {
				transform: translate3d(0, 0, 0);
			}
		}
	}
	&__collapse-menu {
		@include flex();
		@media (max-width: $breakpoint-xmd) {
			position: absolute;
			width: 100%;
			left: -100%;
			top: calc(100% + 14px);
			padding: 35px 0;
			z-index: 1050;
			background-color: color('black');
			transition: left 0.4s;
			@include flex(column, nowrap, center, center, 45px 0);
		}
		&.active {
			left: 0;
		}
	}
	&__nav-link {
		padding: 5px 20px;
		font-size: 20px;
		color: color('white');
		transition: color 0.2s;
		&.active,
		&:hover {
			color: color('golden-yellow');
		}
	}
	&__mob-btn {
		display: none;

		@media (max-width: $breakpoint-xmd) {
			display: block;
		}
		button {
			width: 40px;
			height: 30px;
			padding: 0;
			background-color: transparent;
			border: none;
			position: relative;
			span {
				position: absolute;
				width: 100%;
				height: 3px;
				left: 0;
				background-color: color('golden-yellow');
				&:first-child {
					top: 0;
					transform: translateY(0) rotate(0deg);
					transition: all 0.4s;
				}
				&:nth-child(2) {
					top: 50%;
					transform: translateY(-50%);
				}
				&:last-child {
					bottom: 0;
					transform: translateY(0) rotate(0deg);
					transition: all 0.4s;
				}
			}
			&.active {
				span {
					&:first-child {
						top: 50%;
						transform: translateY(-50%) rotate(45deg);
					}
					&:nth-child(2) {
						display: none;
					}
					&:last-child {
						bottom: 50%;
						transform: translateY(50%) rotate(-45deg);
					}
				}
			}
		}
	}
	&__logo {
		display: none;
		@media (max-width: $breakpoint-xmd) {
			display: block;
		}
		img {
			@media (max-width: $breakpoint-xmd) {
				height: 45px !important;
			}
		}
	}
	.container {
		@include flex(row, nowrap, space-between, center, 0 0);
	}
	&__cart-icon {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;
		@media (max-width: $breakpoint-xmd) {
			display: none;
		}
	}
	&__cart-link {
		span {
			&:first-child {
				display: block;
				@media (max-width: $breakpoint-xmd) {
					display: none;
				}
			}
			&:last-child {
				display: none;
				@media (max-width: $breakpoint-xmd) {
					display: block;
				}
				img {
					vertical-align: middle;
				}
			}
		}
		@media screen and (max-width: $breakpoint-xsm) {
			padding: 0 10px;
		}
	}
}
/* NavBar End */
/* Header Home Start */
header#home {
	color: color('white');
	position: relative;
	line-height: 0;
	&::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: #000;
		opacity: 0.5;
	}
	.text-content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: calc((100% - 1449px) / 2);
		z-index: 2;
		line-height: 1.4;
		@media screen and (max-width: $breakpoint-xxlg) {
			left: 20px;
		}
		h1 {
			font-size: 75px;
			line-height: 1.1;
			margin: 0;
			@media screen and (max-width: $breakpoint-md) {
				font-size: 53px;
			}
			@media screen and (max-width: $breakpoint-xsm) {
				font-size: 44px;
			}
		}
		p {
			font-size: 35px;
			margin: 0;
			@media screen and (max-width: $breakpoint-xsm) {
				font-size: 27px;
			}
		}
	}
}
/* Header Home End */

/* Good Packages Start */
.good-packages {
	padding: 120px 0 95px;
	.container {
		max-width: 1369px;
		@include flex(row, wrap, space-between, stretch, 0 0);
		@media screen and (max-width: $breakpoint-xlg) {
			width: 100%;
			padding: 0 20px;
		}
	}
	&__image {
		max-width: 720px;
	}
	&__text {
		max-width: 600px;
		@include flex(column, wrap, center, stretch, 0 0);
		h2 {
			margin-top: 0;
			color: color('red');
			font-weight: fontWeight('xx-bold');
			font-size: 50px;
			padding-bottom: 35px;
			margin-bottom: 35px;
			position: relative;
			span {
				color: color('blue');
			}
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 69px;
				height: 3px;
				background-color: color('red');
			}
		}
	}
	&__list {
		margin: 75px 0 55px;
		ul {
			height: 100%;
			width: 100%;
			position: relative;
			z-index: 2;
			@include flex(row, wrap, space-between, stretch, 0 0);
			li {
				width: 8%;
				@media screen and (max-width: $breakpoint-lg) {
					width: 16%;
				}
				@media screen and (max-width: $breakpoint-md) {
					width: 40%;
					margin-bottom: 25px;
				}
				p {
					margin: 0;
					&:first-child {
						font-size: 45px;
						color: color('golden-yellow');
					}
				}
				img {
					width: 100%;
				}
			}
		}
	}
}
/* Good Packages End */
/* Footer Start */
footer {
	border-top: 14px solid color('golden-yellow');
	background: $blue-gradient;
	padding: 55px 0 35px;
	.container {
		@include flex(row, wrap, flex-start, stretch, 0 0);
	}
	.footer-item {
		width: 33.33%;
		@include flex(column, nowrap, stretch, center, 0 0);
		color: color('white');
		&:nth-child(2) {
			border-left: 3px solid color('golden-yellow');
			border-right: 3px solid color('golden-yellow');
			@media screen and (max-width: $breakpoint-xxmd) {
				border-left: none;
				border-right: none;
				border-top: 3px solid color('golden-yellow');
				border-bottom: 3px solid color('golden-yellow');
			}
		}
		&:last-child {
			ul {
				li {
					line-height: 1.6;
				}
			}
		}

		a {
			color: inherit;
			transition: color 0.3s;
			&:hover,
			&.active {
				color: color('red');
			}
		}
		@media screen and (max-width: $breakpoint-xxmd) {
			width: 100%;
			align-items: flex-start;
			padding: 35px 0;
		}
	}
}
/* Footer End */

/* Info Start */
.info {
	ul {
		margin-bottom: 45px;
		li {
			@include flex(row, nowrap, space-between, flex-start, 0 0);
			&:not(:last-child) {
				padding-bottom: 25px;
			}
			.content {
				width: 74%;
			}
			span {
				display: block;
				white-space: nowrap;
				line-height: 1.4;
			}
		}
	}
	.social {
		width: 60%;
		a {
			&:not(:last-child) {
				margin-right: 25px;
			}
		}
		@media screen and (max-width: $breakpoint-lg) {
			width: 90%;
		}
	}
}
/* Info End */

/* Map Start */
.map {
	margin-top: 35px;
	iframe {
		width: 100%;
		height: 763px;
	}
}

/* Map End */
/* Contact Start */
.contact h1 {
	color: color('red');
	padding-bottom: 75px;
	position: relative;
	margin-top: 45px;
	font-weight: fontWeight('thiny');
	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 62px;
		height: 3px;
		background-color: color('red');
	}
}
.contact .contact-container {
	@include flex(row, wrap, space-between, flex-start, 0 0);
	margin-top: 75px;
	.contact-form {
		width: 47%;
		@media screen and (max-width: $breakpoint-lg) {
			width: 55%;
		}
		@media screen and (max-width: $breakpoint-xmd) {
			width: 100%;
			margin-bottom: 35px;
		}
		input {
			padding-left: 10px;
			height: 38px;
			width: 100%;
			border: 1px solid color('gray');
			margin-bottom: 35px;
		}
		textarea {
			width: 100%;
			height: 108px;
			padding: 10px 0 0 10px;
			border: 1px solid color('gray');
			margin-bottom: 35px;
		}
		.btn-group {
			display: flex;
			justify-content: flex-end;
			.btn {
				border: none;
				text-transform: uppercase;
				font-weight: fontWeight('x-bold');
			}
		}
	}
	.info {
		width: 22%;
		@media screen and (max-width: $breakpoint-lg) {
			width: 35%;
		}
		@media screen and (max-width: $breakpoint-xmd) {
			width: 100%;
		}
		.social {
			width: 100%;
		}
	}
}
/* Contact End */

/* Categories Start */
#production {
	padding: 75px 0 125px;
	.title {
		text-align: center;
		h2 {
			color: color('red');
			font-size: 40px;
			font-weight: fontWeight('thiny');
			padding-bottom: 35px;
			margin-bottom: 65px;
			position: relative;
			span {
				color: color('blue');
			}
			&::after {
				content: '';
				position: absolute;
				left: 50%;
				bottom: 0;
				width: 62px;
				height: 3px;
				transform: translateX(-50%);
				background-color: color('red');
			}
		}
	}
	.production-cetegories {
		@include flex(row, wrap, space-between, stretch, 0 0);
		.category {
			border: 3px solid color('golden-yellow');
			width: 30%;
			margin-bottom: 35px;
			@media screen and (max-width: $breakpoint-xmd) {
				width: 45%;
			}
			@media screen and (max-width: $breakpoint-md) {
				width: 100%;
			}
			.category__image {
				line-height: 0;
			}
			.category__content {
				@include flex(column, nowrap, center, center, 0 0);
				background-color: color('white');
				position: relative;
				border-top: 3px solid color('golden-yellow');
				z-index: 2;

				.text-content {
					text-align: center;
					h3 {
						color: color('gray');
						a {
							color: inherit;
							transition: color 0.2s;
							&:hover {
								color: color('red');
							}
						}
					}
				}
			}
		}
	}
}
/* Categories End */
/* Banner Start */
.banner {
	min-height: 604px;
	position: relative;
	text-align: center;
	color: #fff;
	@include flex(row, wrap, center, center, 0 0);
	@media screen and (max-width: $breakpoint-xmd) {
		padding: 35px 0;
	}
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		left: 0;
		top: 0;
	}
}
/* Banner End */

/* What We Do Start */
.what-we-do {
	.container {
		.what-we-do-container {
			@include flex(row, wrap, space-between, flex-start, 0 0);
			position: relative;
			z-index: 2;
			.what-we-do-item {
				width: 28%;
				text-align: center;
				@media screen and (max-width: $breakpoint-xmd) {
					width: 100%;
				}
				h3 {
					font-size: 40px;
					color: color('red');
					font-weight: fontWeight('xx-bold');
				}
				&:nth-child(2) h3 {
					margin-bottom: 97px;
				}
			}
		}
	}
}

/* What We Do End */

/* Home Contact Start */
.home-contact {
	.container {
		.home-contact-container {
			@include flex(row, wrap, space-between, flex-start, 0 0);
			position: relative;
			z-index: 2;
			.home-contact-item {
				&:first-child {
					width: 35%;
					@media screen and (max-width: $breakpoint-xmd) {
						width: 100%;
						margin-bottom: 35px;
					}
				}
				&:last-child {
					width: 60%;
					@media screen and (max-width: $breakpoint-xmd) {
						width: 100%;
					}
				}
				.title {
					text-align: left;
					h2 {
						margin: 0;
						color: color('red');
						font-size: 40px;
						font-weight: fontWeight('thiny');
						padding-bottom: 35px;
						margin-bottom: 35px;
						position: relative;
						&::after {
							content: '';
							position: absolute;
							left: 0;
							bottom: 0;
							width: 62px;
							height: 3px;
							background-color: color('red');
						}
					}
				}
				.contact-row {
					@include flex(row, wrap, space-between, flex-start, 0 0);
					margin-bottom: 35px;
					.contact-label {
						width: 30%;
						text-align: right;
						@media screen and (max-width: $breakpoint-md) {
							width: 100%;
							text-align: left;
							margin-bottom: 35px;
						}
					}
					.contact-fielad {
						width: 67%;
						@media screen and (max-width: $breakpoint-md) {
							width: 100%;
						}
						input {
							width: 100%;
							border: none;
							background-color: rgba(255, 255, 255, 0.6);
							height: 38px;
						}
						textarea {
							width: 100%;
							border: none;
							background-color: rgba(255, 255, 255, 0.6);
							height: 108px;
						}
					}
				}
				@media screen and (max-width: $breakpoint-xmd) {
					width: 100%;
				}
			}
		}
	}
}
/* Home Contact End */

/* Shop Start */
.shop {
	.search {
		width: 30%;
		margin: 75px 0;
		@media screen and (max-width: $breakpoint-xmd) {
			width: 100%;
		}
		form {
			@include flex(row, nowrap, flex-start, flex-start, 0 0);
			input {
				height: 56px;
				width: calc(100% - 56px);
				padding-left: 10px;
				border-style: solid;
				border-right: none;
			}
			button {
				height: 56px;
				width: 56px;
				border: none;
				background-color: color('golden-yellow');
				cursor: pointer;
			}
		}
	}
	.shop-container {
		@include flex(row, wrap, space-between, flex-start, 0 0);
		margin-bottom: 75px;
		.categories {
			width: 30%;
			ul {
				li {
					line-height: 1.4;
					a {
						color: color('gray');
						transition: color 0.2s;
						&:hover,
						&.active {
							color: color('red');
						}
					}
				}
			}
			@media screen and (max-width: $breakpoint-xmd) {
				width: 100%;
				margin-bottom: 35px;
			}
		}
		.products {
			width: 65%;
			position: relative;
			@include flex(row, wrap, flex-start, stretch, 30px 3%);
			.loader {
				position: absolute;
				width: 100%;
				left: 0;
				top: 0;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.product-item {
				width: calc(33.333333% - 20px);

				.product-image {
					background-color: rgb(230, 230, 230);
					padding: 35px 0;
					text-align: center;
				}
				.product-content {
					line-height: 1.4;
					h3 {
						margin-bottom: 0;
						font-weight: fontWeight('thiny');
						font-size: 20px;
					}
					ul {
						li {
							color: color('gray');
							&:last-child {
								color: color('red');
								font-weight: fontWeight('x-bold');
								font-size: 24px;
							}
						}
					}
					.add-to-cart-btn {
						width: 58%;
						border: none;
						cursor: pointer;
					}
					.add-to-cart {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-top: 12px;
						.qty {
							display: flex;
							column-gap: 8px;
							.inc,
							.dec {
								border: none;
								background-color: transparent;
								font-size: 28px;
								font-weight: 700;
								color: color('red');
								cursor: pointer;
							}
							input {
								width: 54px;
								height: 54px;
								border-color: color('red');
								border-style: solid;
								font-size: 26px;
								text-align: center;
								color: color('red');
								font-weight: 700;
								&:focus {
									outline-color: color('golden-yellow');
								}
							}
						}
					}
				}
				@media screen and (max-width: $breakpoint-xlg) {
					width: calc(50% - 15px);
				}
				@media screen and (max-width: $breakpoint-md) {
					width: 100%;
				}
			}
			@media screen and (max-width: $breakpoint-xmd) {
				width: 100%;
			}
		}
	}
}
/* Shop End */

/* Cart Start */
.cart {
	.title {
		background-image: $blue-gradient;
		h1 {
			margin: 0;
			line-height: 1.8;
			color: color('golden-yellow');
			font-weight: fontWeight('thiny');
		}
	}
	.wrapper {
		margin: 25px 0;
		form {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			h2 {
				color: color('red');
				font-size: 1.8rem;
				font-weight: fontWeight('thiny');
				margin: 0;
			}
			.form-group {
				display: flex;
				flex-wrap: wrap;
				gap: 20px 20px;
				margin: 20px 0;
				.form-item {
					width: calc(50% - 10px);
					display: flex;
					flex-direction: column;
					gap: 7px;
					@media screen and (max-width: $breakpoint-md) {
						width: 100%;
					}
					input[type='text'] {
						height: 35px;
					}
					input[type='email'] {
						height: 35px;
					}
				}
			}
			.left-side {
				width: 40%;
				input[type='checkbox'] {
					width: 20px;
					height: 20px;
				}
				@media screen and (max-width: $breakpoint-xlg) {
					width: 48%;
				}
				@media screen and (max-width: $breakpoint-lg) {
					width: 100%;
				}
			}
			.right-side {
				width: 40%;
				background-color: color('golden-yellow');
				padding: 15px 50px;
				@media screen and (max-width: $breakpoint-xlg) {
					width: 48%;
				}
				@media screen and (max-width: $breakpoint-lg) {
					width: 100%;
				}
				@media screen and (max-width: $breakpoint-md) {
					padding: 15px 10px;
				}
				input[type='radio'] {
					border-color: color('red') !important;
					width: 20px;
					height: 20px;
					margin: 0;
					&:checked {
						background-color: color('red');
					}
				}
				table {
					width: 100%;
					border-color: color('red');
					border-collapse: collapse;
					th {
						text-align: left;
					}
					tbody {
						tr {
							td {
								padding: 7px 0;
								font-size: 15px;
								font-weight: 600;
								button {
									border: none;
									background-color: color('red');
									color: color('white');
									cursor: pointer;
									margin-right: 8px;
									&:hover {
										color: color('black');
									}
								}
								&:last-child {
									.cell {
										border: 1px solid color('black');
										display: inline-flex;
										column-gap: 5px;
										align-items: center;

										button {
											border: none;
											background-color: transparent;
											font-size: 20px;
											cursor: pointer;
											color: color('black');
											&:hover {
												color: color('red');
											}
										}
										input {
											width: 45px;
											text-align: center;
											border: none;
											background-color: transparent;
											font-size: 18px;
										}
									}
								}
							}
						}
					}
					tfoot {
						tr {
							th,
							td {
								padding: 10px 0;
							}
							&:first-child {
								border-top: 2px solid color('red');
								border-bottom: 2px solid color('red');
							}
						}
					}
				}
				.payment {
					display: flex;
					flex-direction: column;
					label {
						display: flex;
						align-items: center;
						column-gap: 8px;
					}
				}
				.cart-info {
					.delivery {
						padding-left: 25px;
						font-weight: fontWeight('xx-bold');
					}
				}
				button {
					border: none;
					cursor: pointer;
				}
			}
		}
	}
}
/* Cart End */

/*Action Start */
.action {
	position: fixed;
	left: 50%;
	top: -50%;
	transform: translate(-50%, -50%);
	padding: 55px;
	text-align: center;
	background-image: $blue-gradient;
	color: color('white');
	border-radius: 10px;
	z-index: 1060;
	transition: top 0.6s;
	&.active {
		top: 50%;
	}
	.clear {
		position: absolute;
		border-radius: 50%;
		padding: 0;
		width: 42px;
		height: 42px;
		border: 2px solid color('white');
		background-color: color('black');
		color: color('white');
		top: -21px;
		right: -21px;
		cursor: pointer;
	}
	div {
		&:first-of-type {
			font-size: 45px;
			p {
				margin: 0;
			}
		}
		&:nth-of-type(2) {
			font-size: 25px;
		}
	}
	a {
		background-color: color('white');
		display: block;
		padding: 8px 15px;
		text-align: center;
		border-radius: 6px;
		color: color('black');
		text-transform: uppercase;
		&:hover {
			color: color('red');
		}
	}
}
/*Action End */

/* Error success */
.error {
	color: color('red');
}
.success {
	color: color('green');
}
