@mixin flex(
  $direction: row,
  $wrap: wrap,
  $justifyContent: flex-start,
  $alignItems: stretch,
  $gap: 0 0
) {
  display: flex;
  flex-direction: $direction;
  flex-wrap: $wrap;
  justify-content: $justifyContent;
  align-items: $alignItems;
  gap: $gap;
}
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
