$container-width: 1449px;
$num: 12;
$positions: (
	'relative': relative,
	'absolute': absolute,
	'fixed': fixed,
	'sticky': sticky,
);
$font-weights: (
	'xx-thiny': 100,
	'x-thiny': 200,
	'thiny': 300,
	'normal': 400,
	'bold': 500,
	'x-bold': 600,
	'xx-bold': 700,
	'xxx-bold': 800,
	'xxxx-bold': 900,
);
$colors: (
	'blue': rgb(52, 153, 204),
	'red': rgb(237, 28, 36),
	'golden-yellow': rgb(255, 205, 5),
	'black': #333,
	'white': #fff,
	'gray': rgb(100, 100, 100),
	'green': rgb(52, 185, 52),
);
$blue-gradient: linear-gradient(
	to right,
	rgb(0, 101, 172),
	rgb(0, 156, 205),
	rgb(0, 101, 172)
);

$breakpoint-xxsm: 320px;
$breakpoint-xsm: 375px;
$breakpoint-sm: 425px;
$breakpoint-md: 560px;
$breakpoint-xmd: 768px;
$breakpoint-xxmd: 960px;
$breakpoint-lg: 1024px;
$breakpoint-xlg: 1369px;
$breakpoint-xxlg: 1449px;
$breakpoint-xxxlg: 1600px;
