@import './variables';
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: 'Swis721 BT';
  font-size: 18px;
  line-height: 1.4;
  color: map-get($colors, 'gray');
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
h2 {
  font-size: 40px;
}
img {
  max-width: 100%;
}
*,
::after,
::before {
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
